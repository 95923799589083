<template>
  <div class="admin-create-playbook">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <div class="page-header">
      <h1 class="page-title">Create Playbook</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>

    <div class="form">
      <a-form-item label="Playbook Name">
        <a-input size="large" v-model="playbook.displayName"></a-input>
      </a-form-item>

      <a-form-item label="Description">
        <a-input size="large" v-model="playbook.description"></a-input>
      </a-form-item>

      <a-form-item label="Visibility">
        <a-radio-group v-model="playbook.scope">
          <a-radio :value="1">Shown to organisation</a-radio>
          <!-- <a-radio :value="2">Shown to whole tenant</a-radio> -->
          <a-radio :value="3">Hidden from lists</a-radio>
        </a-radio-group>
      </a-form-item>
    </div>

    <a-button
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import checklists from "../../../../api/checklists";
export default {
  data() {
    return {
      isSaving: false,
      playbook: {
        displayName: "",
        description: "",
        scope: 1,
      },
    };
  },

  components: { LoadingScreen },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
    }),
  },

  methods: {
    goBack() {
      this.$router.push("/admin/settings/playbooks");
    },

    getValidationErrors() {
      let errors = [];
      if (this.playbook.displayName.trim().length == 0) {
        errors.push("Please provide a playbook name");
      }
      if (this.playbook.description.trim().length == 0) {
        errors.push("Please provide a description");
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      try {
        await checklists.addChecklist(this.tenantId, {
          ...this.playbook,
          ownerId: this.selectedOrganisation.id,
        });

        vm.isSaving = false;
        vm.$message.success("Playbook created successfully");
        vm.$router.push("/admin/settings/playbooks");
      } catch (e) {
        console.log(e);
        vm.isSaving = false;
        vm.$message.error("Error creating playbook");
      }
    },
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
  },
};
</script>

<style scoped lang="scss">
.admin-create-playbook {
  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>